import { config } from '@fortawesome/fontawesome-svg-core';
// image
import 'react-lazy-load-image-component/src/effects/blur.css';
import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
// theme
//import ThemeProvider from 'src/theme';
// locales
import { LocalizationProvider } from 'src/locales';
// hooks
//import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
// import ProgressBar from 'src/components/progress-bar';
// import { MotionLazy } from 'src/components/animate/motion-lazy';
// import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
// auth
import { AuthProvider, AuthConsumer } from 'src/auth/context/msal';

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};

export const onClientEntry = () => {
  /* Prevents fontawesome auto css insertion */
  config.autoAddCss = false;
};
export const wrapRootElement = ({ element }) => {
  //useScrollToTop();
  //const root = ReactDOM.createRoot(element);

  return (
    <AppRoot>
      <AuthProvider>
        <LocalizationProvider>{element}</LocalizationProvider>
      </AuthProvider>
    </AppRoot>
  );
};
const AppRoot = ({ children }) => {
  return (
    <HelmetProvider>
      <React.Suspense>{children}</React.Suspense>
    </HelmetProvider>
  );
};
