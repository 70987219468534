import { forwardRef } from 'react';
import { graphql, useStaticQuery } from "gatsby";

// hooks
import { useAuthContext } from 'src/auth/hooks';

// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  src?: string | null | undefined;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, src , sx, ...other }, ref) => {
    let logoSrc = src;
    if(!src){
      
      const data  = useStaticQuery<Queries.HeaderPageQuery>(graphql`
      query LayoutLogo {
        allLayout {
          edges {
            node {
              secure
              logo {
                url
              }
            }
          }
        }
      }
      `);
      const { authenticated } = useAuthContext();
      const layouts = data.allLayout.edges.filter((e) => e.node.secure === authenticated);
      if(layouts.length > 0 && layouts[0].node.logo?.url){
        logoSrc = layouts[0].node.logo.url;
      }
    }
    const logo = logoSrc && (
      <Box
        ref={ref}
        src={logoSrc}
        component="img"
        sx={{ width: 45, height: 45, cursor: 'pointer', ...sx }}
      />
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link href="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
